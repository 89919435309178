import { Links } from '@/components/BottomNavbar';
import { apiUrls } from '@/constants/apiUrls';
import { useMetrics } from '@/hooks/useMetrics';
import { get } from '@/services/api';
import { useNavigate } from 'react-router-dom';

export const usePendingContentRedirect = () => {
  const metrics = useMetrics();
  const navigate = useNavigate();
  const handlePendingContentRedirect = async () => {
    metrics.track({
      action: 'click',
      extra_data: {
        button: metrics.MetricsKeys.CLICK_PENDING_CONTENT,
        path: window.location.pathname,
      },
    });
    const result = await get(apiUrls.users.pending);
    if (result?.data) {
      if (result.data.includes('/content')) {
        return navigate(Links.PENDING);
      }
      window.location.href = result.data;
    }
  };
  return {
    handlePendingContentRedirect,
  };
};
