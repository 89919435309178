export const checkIconURL =
  'https://hacku-front-assets.s3.amazonaws.com/icons/checkmark.svg';

export enum ContentType {
  FEEDBACK = 'feedback',
  EVALUATION = 'evaluation',
  READ = 'lecturas',
  VIDEO = 'video',
  VIDEOS = 'videos',
  API_VIDEO = 'api_video',
  IMAGE = 'visual',
  AUDIO = 'audio',
  SCORM = 'scorm-v2',
  SURVEY = 'survey',
  LIVE = 'live',
}

export enum MetricsKeys {
  DOWNLOAD_CERTIFICATE = 'download-certificate',
  LEARNING_PATH = 'learning-path',
  NAVIGATE_CERTIFICATE_DETAIL = 'navigate-certificate-detail',
  NAVIGATE_TO_CERTIFICATE = 'navigate-to-certificate',
  NAVIGATE_TO_CERTIFICATES = 'navigate-to-certificates',
  NAVIGATE_TO_LEARNING = 'navigate-to-learning',
  NAVIGATE_TO_MODULE = 'navigate-to-module',
  NAVIGATE_TO_CONTENT = 'navigate-to-content',
  NAVIGATE_TO_MY_CONTENTS = 'navigate-to-my-contents',
  NAVIGATE_TO_MY_COURSES = 'navigate-to-my-courses',
  NAVIGATE_TO_MY_FAVOURITES = 'navigate-to-my-favourites',
  NAVIGATE_TO_POSITION = 'navigate-to-position',
  NAVIGATE_TO_PROFILE = 'navigate-to-profile',
  SEARCH_CONTENT = 'search-content',
  SEARCH_USER = 'search-user',
  SELECT_MODULE = 'select-module',
  SHARE_CERTIFICATE = 'share-certificate',
  SHOW_MODAL_HACKU_POINTS = 'show-modal-hacku-points',
  START_EVALUATION = 'start-evaluation',
  CLICK_PENDING_CONTENT = 'click-pending-content',
}
